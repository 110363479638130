import React, { useState } from 'react';
import '../styles/modal.css';
import EditClientForm from './EditClientForm';

const ModalClient = ({ isOpen, onClose, client, onClientUpdate, onDeleteClick, conditionallyShowDeleteButton }) => {
  const [isEditing, setIsEditing] = useState(false);

  if (!isOpen) return null;

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleEditComplete = (updatedClient) => {
    setIsEditing(false);
    if (onClientUpdate) {
      onClientUpdate(updatedClient);
    }
    onClose();
  };
  
  return (
    <div className="modal-overlay-client">
      <div className="modal-content-client">
        <button className="modal-close-client" onClick={onClose}>&times;</button>
        {!isEditing ? (
          <>
            <h2>{client.sCliente}</h2>
            <div className='modal-info-client'>
              <p><strong>NIF:</strong> {client.sNIF}</p>
              <p><strong>Tipo de Cliente:</strong> {client.categoria}</p>
              <p><strong>Email:</strong> {client.sEmail}</p>
            </div>
            <div className='modal-info-client'>
              <p><strong>ID Cliente:</strong> {client.idCliente}</p>
              <p><strong>Dirección:</strong> {client.sDireccion}, {client.sPoblacion}, {client.sProvincia}, {client.sCP}</p>
              <p><strong>Estado:</strong> {client.status}</p>
              {client.bBorrado && <p><strong>Cliente eliminado</strong></p>}
            </div>
            <div className='modal-info-client-action'>
              <button className="edit-button" onClick={handleEditClick} disabled={client.bBorrado}>Editar</button>
              {conditionallyShowDeleteButton && conditionallyShowDeleteButton(client)}
            </div>
          </>
        ) : (
          <EditClientForm client={client} onEditComplete={handleEditComplete} />
        )}
      </div>
    </div>
  );
};

export default ModalClient;