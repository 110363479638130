import ModalSchedule from "../components/ModalSchedule";
import MonthlySchedule from "../components/MonthlySchedule";
import React, { useState } from "react";
import "../styles/createschedule.css";

const Schedule = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <div className="container-schedule">
      <h2>Schedule</h2>
      <button onClick={openModal}>Crear Evento de Trabajo</button>
      <MonthlySchedule />
      <ModalSchedule isOpen={isOpen} onClose={closeModal} />
    </div>
  )
};

export default Schedule;