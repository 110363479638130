import { useAuth } from '../AuthContext';
import { Link } from'react-router-dom';
import '../styles/buttons.css';

const AddStaffButton = () => {
    const { isAuthenticated } = useAuth();
    return (
        isAuthenticated && <Link to={"/Saas/admin/registerstaff"} className='add-button' style={{textDecoration: "none"}}>Añadir</Link>
    );
};

export default AddStaffButton;