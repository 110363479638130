import UserStaff from '../components/UsersStaff';
import GoBack from '../components/GoBack';
import "../styles/staff.css";
import AddStaffButton from '../components/AddStaffButton';

const Staff = () => {
    return (
        <div className='main-staff'>
            <GoBack />
            <AddStaffButton />
            <UserStaff />
        </div>
    );
};

export default Staff;