import React from "react";
import "../styles/termsservice.css";
import GoBack from "../components/GoBack";

const termsOfService = () => (
  <div className="TerSo">
    <h1>Términos de Servicio de WorkSuite</h1>

    <h2>1. Acceso al Servicio</h2>
    <p>
      WorkSuite es un servicio de software como servicio (SaaS) proporcionado
      por Infoprol que ofrece registros de jornada laboral, solicitudes de
      materiales, notificaciones de incidencias y administración de inventario y
      horarios para empresas.
    </p>
    <p>
      1.1. WorkSuite es accesible únicamente por invitación. No se ofrece ningún
      período de prueba gratuito.
    </p>
    <p>
      1.2. Los términos de pago se rigen por un contrato separado entre el
      cliente y Infoprol
    </p>
    <p>1.3. No existen límites de uso en el servicio SaaS.</p>

    <h2>2. Recopilación y Protección de Datos</h2>
    <p>2.1. WorkSuite recopila datos sensibles de los empleados, incluyendo:</p>
    <ul>
      <li>DNI</li>
      <li>Ubicación durante las horas de trabajo</li>
      <li>Salario</li>
      <li>Horario</li>
      <li>Días de vacaciones</li>
      <li>Número de la Seguridad Social</li>
      <li>Correo electrónico</li>
      <li>Número de Teléfono</li>
    </ul>
    <p>
      2.2. Todos los datos se almacenan y protegen de acuerdo con las leyes de
      protección de datos españolas vigentes.
    </p>

    <h2>3. Propiedad Intelectual</h2>
    <p>
      3.1. Toda la propiedad intelectual asociada con WorkSuite es propiedad
      exclusiva de Infoprol
    </p>

    <h2>4. Modificación, Suspensión o Terminación del Servicio</h2>
    <p>
      4.1. Infoprol se reserva el derecho de modificar, suspender o
      terminar el servicio con un preaviso de dos meses, excepto en caso de
      incumplimiento del contrato o por acuerdo de ambas partes.
    </p>

    <h2>5. Garantía de Servicio</h2>
    <p>
      5.1. Infoprol garantiza que el servicio no se interrumpirá por más de
      2 días laborables o 6 días festivos españoles.
    </p>
    <p>
      5.2. En caso de incumplimiento de esta garantía, se podria compensar al cliente
      con un perioda de servicio gratuito.
    </p>

    <h2>6. Reembolsos y Cancelación</h2>
    <p>
      6.1. Los reembolsos solo son posibles dentro de los 2 días posteriores al
      pago mensual.
    </p>
    <p>
      6.2. La cancelación del servicio será efectiva el mes siguiente a la
      notificación y procesado de la misma.
    </p>

    <h2>7. Soporte Técnico</h2>
    <p>7.1. El soporte técnico se proporciona a través de:</p>
    <ul>
      <li>Correo electrónico: soporte@infoprol.es</li>
      <li>Teléfono proporcionado a la empresa</li>
    </ul>

    <h2>8. Cambios en los Términos de Servicio</h2>
    <p>
      8.1. Cualquier cambio en estos términos de servicio se notificará con 2
      meses de antelación mediante:
    </p>
    <ul>
      <li>Correo electrónico: admin@infoprol.es</li>
      <li>Otra via comunicación escrita verificada</li>
    </ul>

    <h2>9. Ley Aplicable y Jurisdicción</h2>
    <p>9.1. Estos términos se rigen por las leyes españolas vigentes.</p>
    <p>
      9.2. Todas las disputas deben resolverse bajo la jurisdicción española.
    </p>

    <p>
      Al utilizar WorkSuite, usted acepta cumplir con estos términos de
      servicio. Si tiene alguna pregunta o inquietud, no dude en ponerse en
      contacto con nosotros a través de los canales de soporte mencionados
      anteriormente.
    </p>
    <GoBack />
  </div>
);

export default termsOfService;
