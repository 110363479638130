import GoBack from '../components/GoBack';
import SuppliesList from '../components/SuppliesList';

const Suppliers = () => {
    return (
        <div className='main-item'>
            <GoBack />
            <SuppliesList />
        </div>
    );
};

export default Suppliers;