import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import config from '../config';
import { Circles } from 'react-loader-spinner';
import '../styles/itemRequests.css';
import ModalRequest from './ModalRequest';
import GoBack from './GoBack';
import SearchComponent from './SearchComponent';
import { useAuth } from '../AuthContext';

const ItemRequestComponent = () => {
  const [requests, setRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const { getAuthToken } = useAuth();
  const userId = localStorage.getItem('userId');

  const fetchRequests = useCallback(async () => {
    try {
      setLoading(true);
      const token = getAuthToken();
      const response = await axios.get(`${config.production.API_BASE_URL}get_item_requests.php?userId=${userId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (response.data.requests) {
        setRequests(response.data.requests);
        setFilteredRequests(response.data.requests);
      } else if (response.data.error) {
        throw new Error(response.data.error);
      } else {
        throw new Error('La estructura de datos no es la esperada');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error details:', error);
      setError(error.message || 'Ha ocurrido un error al cargar los datos');
      setLoading(false);
    }
  }, [getAuthToken, userId]);

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);
  
  const handleSearch = (searchTerm) => {
    const filtered = requests.filter((request) =>
      Object.values(request).some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredRequests(filtered);
  };

  const handleRowClick = (request) => {
    setSelectedRequest(request);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRequest(null);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const handleUpdateRequest = async (id, updateData) => {
    try {
      const token = getAuthToken();
      await axios.post(`${config.production.API_BASE_URL}update_estado_item.php`, 
        { id, ...updateData, userId },
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      const updatedRequests = requests.map(req =>
        req.idMovimiento === id ? { ...req, ...updateData } : req
      );
      setRequests(updatedRequests);
      setFilteredRequests(updatedRequests);
      handleCloseModal();
    } catch (error) {
      console.error('Error updating request:', error);
      setError('Error al actualizar la solicitud');
    }
  };

  if (loading) return (
    <div style={{ width: "100%", height: "90vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Circles height="80" width="80" color="#000000" ariaLabel="circles-loading" />
    </div>
  );

  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  if (requests.length === 0) {
    return <div className="no-data-message">No hay solicitudes de artículos disponibles.</div>;
  }

  return (
    <div className="article-requests-container">
      <div className="article-requests-title">
        <GoBack style={{margin: 0}}/>
        <h2>Solicitudes de Artículos</h2>
      </div>
      <div className="article-requests-filter">
        <SearchComponent onSearch={handleSearch} />
      </div>
      <table className="article-requests-table">
        <thead>
          <tr>
            <th>Nº Pedido</th>
            <th>Nombre</th>
            <th>Estado</th>
            <th>Ultima Actualización</th>
            <th>Unidades</th>
            <th>Operarios</th>
            <th>Cliente</th>
          </tr>
        </thead>
        <tbody>
          {filteredRequests.map((request) => (
            <tr key={request.idMovimiento} onClick={() => handleRowClick(request)}>
              <td>{request.idMovimiento}</td>
              <td>{request.nombre}</td>
              <td>{request.estado}</td>
              <td>{formatDate(request.dFechaMovimiento)}</td>
              <td>{request.iUnidades}</td>
              <td>{request.operario}</td>
              <td>{request.sCliente}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ModalRequest
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        request={selectedRequest}
        onUpdateRequest={handleUpdateRequest}
      />
    </div>
  );
};

export default ItemRequestComponent;