import GoBack from '../components/GoBack';
import WorkLogList from '../components/WorkLogList';

const WorkLog = () => {
    return (
        <div className="worklog">
            <GoBack />
            <WorkLogList />
        </div>
    );
};

export default WorkLog;