import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronRight, BookMarked, Layers, User } from 'lucide-react';
import axios from 'axios';
import config from '../config';
import { useAuth } from '../AuthContext';
import '../styles/sidebar.css';

const iconMap = {
  'User': User,
  'BookMarked': BookMarked,
  'Layers': Layers
};

const SidebarItem = ({ item, isOpen, toggleOpen }) => {
  const IconComponent = item.icon ? iconMap[item.icon] : null;

  return (
    <div className="sidebar-item">
      <a
        href={item.route}
        onClick={(e) => {
          if (!item.route) {
            e.preventDefault();
          }
          toggleOpen();
        }}
        className="sidebar-item-link"
      >
        <span className="sidebar-item-icon">
          {IconComponent && <IconComponent size={20} />}
        </span>
        <span className="sidebar-item-text">{item.name}</span>
        {item.subItems && item.subItems.length > 0 && (
          <span className="sidebar-item-chevron">
            {isOpen ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
          </span>
        )}
      </a>
      {isOpen && item.subItems && item.subItems.length > 0 && (
        <div className="sidebar-subitem-container">
          {item.subItems.map((subItem, index) => (
            <a
              key={index}
              href={subItem.route}
              className="sidebar-subitem-link"
            >
              {subItem.name}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

const Sidebar = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [openItems, setOpenItems] = useState({});
  const { getAuthToken } = useAuth();
  const userId = localStorage.getItem('userId');
  const environment = process.env.NODE_ENV || 'development';
  const apiUrl = config[environment].API_BASE_URL;

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const token = getAuthToken();
        const response = await axios.get(`${apiUrl}get_menu_items.php?userId=${userId}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setMenuItems(response.data);
      } catch (error) {
        console.error('Error al obtener elementos del menú:', error);
      }
    };

    fetchMenuItems();
  }, [userId, getAuthToken, apiUrl]);

  const toggleItem = (itemId) => {
    setOpenItems(prev => ({ ...prev, [itemId]: !prev[itemId] }));
  };

  return (
    <div className="sidebar">
      <nav className="sidebar-nav">
        {menuItems.map((item) => (
          <SidebarItem
            key={item.id}
            item={item}
            isOpen={openItems[item.id]}
            toggleOpen={() => toggleItem(item.id)}
          />
        ))}
      </nav>
    </div>
  );
};

export default Sidebar;