import React, { useState, useEffect } from "react";
import axios from "axios";
import "../styles/register-client.css";
import config from '../config';
import GoBack from '../components/GoBack';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import { useAuth } from '../AuthContext';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function LocationMarker({ position, setPosition }) {
  const map = useMapEvents({
    click(e) {
      setPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    },
  });

  return position === null ? null : (
    <Marker position={position} />
  );
}


function RegisterClient() {
  const [formData, setFormData] = useState({
    sCliente: "",
    sNIF: "",
    sDireccion: "",
    sPoblacion: "",
    sCP: "",
    sProvincia: "",
    TCliente: "",
    sGeoloc: "",
    sTipoVia: "",
    sTel1: "",
    sTel2: "",
    sWeb: "",
    sEmail: "",
    sContacto: "",
  });
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [position, setPosition] = useState(null);
  const environment = process.env.NODE_ENV || 'development';
  const { getAuthToken } = useAuth();
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    if (position) {
      setFormData(prevState => ({
        ...prevState,
        sGeoloc: `${position.lat},${position.lng}`
      }));
    }
  }, [position]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setFormData({
      sCliente: "",
      sNIF: "",
      sDireccion: "",
      sPoblacion: "",
      sCP: "",
      sProvincia: "",
      TCliente: "",
      sGeoloc: "",
      sTipoVia: "",
      sTel1: "",
      sTel2: "",
      sWeb: "",
      sEmail: "",
      sContacto: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    try {
      const token = getAuthToken();
      const response = await axios.post(
        config[environment].PHP_REGISTER_CLIENT_URL,
        { ...formData, userId },
        {
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );

      if (response.data.message) {
        setMessage(response.data.message);
        resetForm();
      } else {
        setError("Ocurrió un error inesperado. Por favor, inténtelo de nuevo.");
      }
    } catch (error) {
      setError(
        error.response?.data?.message ||
          "Ocurrió un error durante el registro."
      );
      console.error("Error:", error);
    }
  };

  return (
    <div className="RegFormClient">
      <h1 className="RegTitClient">Registrar un nuevo cliente</h1>
      <form onSubmit={handleSubmit} className="RegForClient">
        <div className="Reg-2-Client">
          <div className="Reg-1-Client">
            <label className="RegLabClient">Nombre del cliente</label>
            <input
              type="text"
              name="sCliente"
              value={formData.sCliente}
              onChange={handleChange}
              placeholder="Ingresar nombre del cliente"
              required
              className="RegInpClient"
            />
            <label className="RegLabClient">NIF</label>
            <input
              type="text"
              name="sNIF"
              value={formData.sNIF}
              onChange={handleChange}
              placeholder="Ingresar NIF"
              required
              className="RegInpClient"
            />
            <label className="RegLabClient">Dirección</label>
            <input
              type="text"
              name="sDireccion"
              value={formData.sDireccion}
              onChange={handleChange}
              placeholder="Ingresar dirección"
              required
              className="RegInpClient"
            />
            <label className="RegLabClient">Población</label>
            <input
              type="text"
              name="sPoblacion"
              value={formData.sPoblacion}
              onChange={handleChange}
              placeholder="Ingresar población"
              required
              className="RegInpClient"
            />
          </div>
          <div className="Reg-1-Client">
            <label className="RegLabClient">Código Postal</label>
            <input
              type="text"
              name="sCP"
              value={formData.sCP}
              onChange={handleChange}
              placeholder="Ingresar código postal"
              required
              className="RegInpClient"
            />
            <label className="RegLabClient">Provincia</label>
            <input
              type="text"
              name="sProvincia"
              value={formData.sProvincia}
              onChange={handleChange}
              placeholder="Ingresar provincia"
              required
              className="RegInpClient"
            />
            <label className="RegLabClient">Tipo de cliente</label>
            <select
              name="TCliente"
              value={formData.TCliente}
              onChange={handleChange}
              required
              className="RegInpClient"
            >
              <option value="" disabled>Selecciona un tipo de cliente</option>
              <option value="1">Empresa</option>
              <option value="2">Particular</option>
            </select>
            <label className="RegLabClient">Geolocalización</label>
            <div className="map-container">
              <MapContainer center={[40.4168, -3.7038]} zoom={5} style={{ height: "300px", width: "100%" }}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <LocationMarker position={position} setPosition={setPosition} />
              </MapContainer>
            </div>
            <input
              type="text"
              name="sGeoloc"
              value={formData.sGeoloc}
              onChange={handleChange}
              placeholder="Coordenadas (selecciona en el mapa)"
              required
              className="RegInpClient"
              readOnly
            />
          </div>
        </div>
        <div className="Reg-3-Client">
          <label className="RegLabClient">Tipo de vía</label>
          <input
            type="text"
            name="sTipoVia"
            value={formData.sTipoVia}
            onChange={handleChange}
            placeholder="Ingresar tipo de vía"
            required
            className="RegInpClient"
          />
          <label className="RegLabClient">Teléfono 1</label>
          <input
            type="tel"
            name="sTel1"
            value={formData.sTel1}
            onChange={handleChange}
            placeholder="Ingresar teléfono principal"
            required
            className="RegInpClient"
          />
          <label className="RegLabClient">Teléfono 2</label>
          <input
            type="tel"
            name="sTel2"
            value={formData.sTel2}
            onChange={handleChange}
            placeholder="Ingresar teléfono secundario (opcional)"
            className="RegInpClient"
          />
          <label className="RegLabClient">Sitio web</label>
          <input
            type="url"
            name="sWeb"
            value={formData.sWeb}
            onChange={handleChange}
            placeholder="Ingresar sitio web (opcional)"
            className="RegInpClient"
          />
          <label className="RegLabClient">Email</label>
          <input
            type="email"
            name="sEmail"
            value={formData.sEmail}
            onChange={handleChange}
            placeholder="Ingresar email"
            required
            className="RegInpClient"
          />
          <label className="RegLabClient">Contacto</label>
          <input
            type="text"
            name="sContacto"
            value={formData.sContacto}
            onChange={handleChange}
            placeholder="Ingresar nombre de contacto"
            required
            className="RegInpClient"
          />
        </div>
        <button type="submit" className="RegButClient">
          Registrar Cliente
        </button>
        <div className="SuErMessageClient">
          {message && <p className="success-message">{message}</p>}
          {error && <p className="error-message">{error}</p>}
        </div>
        <GoBack />
      </form>
    </div>
  );
}

export default RegisterClient;