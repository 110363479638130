import React, { useState } from 'react';
import '../styles/modal.css';

const Modal = ({ isOpen, onClose, supply, onUpdate }) => {
  const [editedSupply, setEditedSupply] = useState(supply);
  const [error, setError] = useState('');

  if (!isOpen) return null;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedSupply(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onUpdate(editedSupply);
      onClose();
    } catch (error) {
      console.error('Error al actualizar el artículo:', error);
      setError('Error al actualizar el artículo. Por favor, inténtelo de nuevo.');
    }
  };

  return (
    <div className="modal-overlay-item">
      <div className="modal-content-item">
        <button className="modal-close-item" onClick={onClose}>&times;</button>
        <h2>Editar Artículo: {editedSupply.sArticuloLargo}</h2>
        <form onSubmit={handleSubmit} className='modal-form-item'>
          <div>
            <label htmlFor="sArticuloCorto">Nombre Corto:</label>
            <input
              type="text"
              id="sArticuloCorto"
              name="sArticuloCorto"
              value={editedSupply.sArticuloCorto}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label htmlFor="sArticuloLargo">Nombre Largo:</label>
            <input
              type="text"
              id="sArticuloLargo"
              name="sArticuloLargo"
              value={editedSupply.sArticuloLargo}
              onChange={handleInputChange}
            />
          </div>
          <div className='modal-submit-item'>
            <button type="submit">Guardar Cambios</button>
          </div>
        </form>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default Modal;