import React from 'react';
import "../styles/buttons.css";
import { useTranslation } from '../TranslationContext';

const Client = ({ clientName }) => {
    const { t } = useTranslation();
    return (
        <div className="CC-1">
            <h1><strong>{t('client')}:</strong></h1>
            <h1>{clientName}</h1>
        </div>
    );
};

export default Client;