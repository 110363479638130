import '../styles/keysol.css';

const CaracteristicasClave = () => {

  const caracteristicas = [
    {
      icon: '📈',
      title: 'Escalabilidad',
      description: 'Escala fácilmente tu infraestructura para satisfacer las demandas crecientes.'
    },
    {
      icon: '🔒',
      title: 'Seguridad',
      description: 'Protege tus datos y activos con nuestras sólidas medidas de seguridad.'
    },
    {
      icon: 'ℹ️',
      title: 'Análisis',
      description: 'Obtén información profunda sobre tu negocio con nuestras herramientas de análisis.'
    }
  ];


  return (
    <div className="caracteristicas-clave">
      <h1>Características Clave</h1>
      <p className="subtitle">Descubre las poderosas características que distinguen nuestra plataforma.</p>
      <div className="caracteristicas-grid">
        {caracteristicas.map((caracteristica, index) => (
          <div key={index} className="caracteristica-card">
            <div className="caracteristica-icon">{caracteristica.icon}</div>
            <h3>{caracteristica.title}</h3>
            <p>{caracteristica.description}</p>
          </div>
        ))}
      </div>
      <footer className="footer">
        <p>© 2024 Infoprol. Todos los derechos reservados. Este sitio web y su contenido están sujetos a nuestros <a href="/#/terms">Términos de Servicio</a> y <a href="/#/privacy">Política de Privacidad</a>. El uso de este sitio constituye la aceptación de estos acuerdos.</p>
      </footer>
    </div>
  );
};

export default CaracteristicasClave;