import { useEffect } from 'react';
import moment from 'moment';

const LocaleUpdater = () => {
  useEffect(() => {
    moment.updateLocale('en', {
    });
  }, []);

  return null;
};

export default LocaleUpdater;