import { Link } from "react-router-dom";
import "../styles/choosingpage.css";

const ChoosingPage = () => {
    return (
        <div className="enter-buttons">
            <Link to="/Saas/workdaylog">
                <button className="enter-button-2">Registro de jornada</button>
            </Link>
            <Link to="/Saas/materialrequests">
                <button className="enter-button-2">Petición de Material</button>
            </Link>
        </div>
    );
};

export default ChoosingPage;