import "../styles/home.css";
import SecSol from "../components/secSol";
import KeySol from "../components/keySol";

const Home = () => {
  return (
    <div>
      <div className="home">
        <h1>Libera tu Potencial Digital</h1>
        <p>
          Descubre nuestra suite de herramientas y servicios poderosos para
          elevar tu negocio en el panorama digital.
        </p>
      </div>
      <SecSol />
      <KeySol />
    </div>
  );
};

export default Home;
