import "../styles/buttons.css"
import { Link } from "react-router-dom";

const EnterButton = () => {
    return (
        <Link to="/login">
            <button className="enter-button">Entrar</button>
        </Link>
    );
};

export default EnterButton;