import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../styles/buttons.css"

const BackButton = ({ style }) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <button onClick={goBack} className="back-button" style={style}>
      Atrás
    </button>
  );
};

export default BackButton;