import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import "../styles/buttons.css";
import Modal from './ModalChooseClient';
import { useTranslation } from '../TranslationContext';

const DropDown = ({ onClientChange }) => {
    const { t } = useTranslation();
    const [clients, setClients] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedClientId, setSelectedClientId] = useState('');

    const environment = process.env.NODE_ENV || 'development';
    const apiUrl = config[environment].API_BASE_URL;

    useEffect(() => {
        const fetchClients = async () => {
            const userId = localStorage.getItem('userId');
            if (!userId) {
                console.error('No se encontró userId en localStorage');
                return;
            }

            try {
                const response = await axios.get(`${apiUrl}get_clients.php?userId=${userId}`);
                setClients(response.data);
            } catch (error) {
                console.error('Error al obtener los clientes:', error);
            }
        };

        fetchClients();
    }, [apiUrl]);

    const handleChange = (event) => {
        const newClientId = event.target.value;
        setSelectedClientId(newClientId);
        if (newClientId === 'otro') {
            setIsModalOpen(true);
        } else {
            onClientChange(newClientId);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedClientId('');
    };

    return (
        <div className='CC-2'>
            <label>{t('another_place')}</label>
            <select 
                onChange={handleChange} 
                value={selectedClientId}
            >
                <option value="" disabled>{t('another_client')}</option>
                {clients.map(client => (
                    <option key={client.idCliente} value={client.idCliente}>
                        {client.sCliente}
                    </option>
                ))}
                <option value="otro">Otro</option>
            </select>
            {isModalOpen && (
                <Modal onClose={handleCloseModal}>
                    <h2>Añadir nuevo cliente</h2>
                </Modal>
            )}
        </div>
    );
};

export default DropDown;