import React from 'react';
import "../styles/navbar.css";
import EnterButton from './enterButton';

const NavBar = () => {
    return (
        <nav className='nav-navbar'>
            <div className="nhome">
                <a href="/" className="t1">Edmoga</a>
                <EnterButton />
            </div>
        </nav>
    )
}

export default NavBar;