import React from 'react';
import '../styles/modal.css';

const Modal = ({ isOpen, onClose, user, onOpenPermissions }) => {
  if (!isOpen || !user) return null;

  return (
    <div className="modal-overlay-staff">
        <div className="modal-content-staff-1">
          <div className="modal-content-staff-1-1">
            <div className="modal-content-staff-1-1-1">
              <h2>{user.sNombre_Completo}</h2>
              <p><strong>Usuario:</strong> {user.sUsuario}</p>
              <p><strong>Categoría:</strong> {user.categoria}</p>
              <p><strong>Email:</strong> {user.sEmail}</p>
            </div>
            <div className="modal-content-staff-1-1-2">
              <button className="modal-close-staff" onClick={onClose}>&times;</button>
            </div>
          </div>
          <div className="modal-content-staff-1-2">
            <div className="modal-content-staff-1-2-1">
              <button className='modal-edit-staff' onClick={() => onOpenPermissions(user)}>
                Editar Permisos
              </button>
              <button className='modal-edit-staff' onClick={() => onOpenPermissions(user)}>
                Editar Operario
              </button>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Modal;