import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import '../styles/monthlyschedule.css';

const MonthlySchedule = () => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedUser, setSelectedUser] = useState('');
  const [users, setUsers] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [schedules, setSchedules] = useState({});

  const environment = process.env.NODE_ENV || 'development';

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(config[environment].PHP_GET_USERS_URL);
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [environment]);

  useEffect(() => {
    const fetchSchedules = async () => {
      if (selectedUser && selectedMonth && selectedYear) {
        try {
          const response = await axios.get(config[environment].PHP_GET_SCHEDULES_URL, {
            params: { userId: selectedUser, month: selectedMonth, year: selectedYear }
          });
          setSchedules(response.data);
        } catch (error) {
          console.error('Error fetching schedules:', error);
        }
      }
    };

    fetchSchedules();
  }, [selectedUser, selectedMonth, selectedYear, environment]);

  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  const getFirstDayOfMonth = (month, year) => {
    return new Date(year, month - 1, 1).getDay();
  };

  const renderCalendar = () => {
    const daysInMonth = getDaysInMonth(selectedMonth, selectedYear);
    const firstDay = getFirstDayOfMonth(selectedMonth, selectedYear);
    const days = [];

    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} className="empty-day"></div>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const hasSchedule = schedules[day] && schedules[day].length > 0;
      days.push(
        <div
          key={day}
          className={`calendar-day ${hasSchedule ? 'has-schedule' : ''}`}
          onClick={() => openModal(day)}
        >
          {day}
        </div>
      );
    }

    return days;
  };

  const openModal = (day) => {
    setSelectedDay(day);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedDay(null);
  };

  const renderModal = () => {
    if (!modalOpen || !selectedDay) return null;

    const daySchedules = schedules[selectedDay] || [];

    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <h3>Clientes programados {selectedDay}/{selectedMonth}/{selectedYear}</h3>
          {daySchedules.length > 0 ? (
            <ul>
              {daySchedules.map((schedule, index) => (
                <li key={index}>
                  Cliente: {schedule.clientName}, Hora: {schedule.startTime} - {schedule.endTime}
                </li>
              ))}
            </ul>
          ) : (
            <p>No hay clientes programados.</p>
          )}
          <button onClick={closeModal}>Cerrar</button>
        </div>
      </div>
    );
  };

  return (
    <div className="monthly-schedule">
      <div className="controls">
        <select
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(Number(e.target.value))}
        >
          {[...Array(12)].map((_, i) => (
            <option key={i + 1} value={i + 1}>
              {new Date(2000, i, 1).toLocaleString('default', { month: 'long' })}
            </option>
          ))}
        </select>
        <select
          value={selectedYear}
          onChange={(e) => setSelectedYear(Number(e.target.value))}
        >
          {[...Array(10)].map((_, i) => (
            <option key={i} value={new Date().getFullYear() - 5 + i}>
              {new Date().getFullYear() - 5 + i}
            </option>
          ))}
        </select>
        <select
          value={selectedUser}
          onChange={(e) => setSelectedUser(e.target.value)}
        >
          <option value="">Seleccionar Operario</option>
          {users.map((user) => (
            <option key={user.idUsuario} value={user.idUsuario}>
              {user.sNombre_Completo}
            </option>
          ))}
        </select>
      </div>
      <div className="calendar">
        <div className="weekdays">
          <div>Domingo</div>
          <div>Lunes</div>
          <div>Martes</div>
          <div>Miércoles</div>
          <div>Jueves</div>
          <div>Viernes</div>
          <div>Sábado</div>
        </div>
        <div className="days">
          {renderCalendar()}
        </div>
      </div>
      {renderModal()}
    </div>
  );
};

export default MonthlySchedule;