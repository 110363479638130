import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import '../styles/crono.css';

const StartButton = ({ onClick }) => (
  <button 
    className="chrono-button start-button"
    onClick={onClick}
    aria-label="Iniciar marcaje"
  >
    <svg 
      viewBox="0 0 24 24" 
      fill="currentColor"
      width="128"
      height="128"
    >
      <path d="M8 5v14l11-7z" />
    </svg>
  </button>
);

const StopButton = ({ onClick }) => (
  <button 
    className="chrono-button stop-button"
    onClick={onClick}
    aria-label="Detener marcaje"
  >
    <svg 
      viewBox="0 0 24 24" 
      fill="currentColor"
      width="128"
      height="128"
    >
      <rect x="6" y="6" width="12" height="12" />
    </svg>
  </button>
);

const ChronoButton = () => {
  const [isRunning, setIsRunning] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [marcajeId, setMarcajeId] = useState(null);
  const [error, setError] = useState(null);

  const environment = process.env.NODE_ENV || 'development';
  const apiUrl = config[environment].API_BASE_URL;

  const startChrono = async () => {
    try {
      setError(null);
      const userId = localStorage.getItem('userId');
      console.log('userId:', userId);
      
      if (!userId) {
        throw new Error('No se encontró la información del usuario');
      }

      const clientId = localStorage.getItem('selectedClientId');
      console.log('clientId:', clientId);
      
      if (!clientId) {
        throw new Error('No se ha seleccionado ningún cliente');
      }

      const currentDate = new Date();
      const horaIni = currentDate.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });
      const dFecha = currentDate.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });

      const payload = {
        idUsuario: parseInt(userId),
        idCliente: parseInt(clientId),
        HoraIni: horaIni,
        dFecha: dFecha
      };

      console.log('Payload para start_marcaje:', payload);
      console.log('URL de la API:', `${apiUrl}start_marcaje.php`);

      const response = await axios.post(`${apiUrl}start_marcaje.php`, payload);
      console.log('Respuesta de start_marcaje:', response.data);

      if (response.data.success) {
        const newMarcajeId = response.data.marcajeId;
        setMarcajeId(newMarcajeId);
        setStartTime(currentDate);
        setIsRunning(true);
        localStorage.setItem('marcajeId', newMarcajeId);
      } else {
        throw new Error(response.data.error || 'Error al iniciar el marcaje');
      }
    } catch (error) {
      console.error('Error detallado al iniciar marcaje:', error);
      const errorMessage = error.response?.data?.error || error.message || 'Error al iniciar el marcaje';
      setError(errorMessage);
      setIsRunning(false);
    }
  };

  const stopChrono = async () => {
    try {
      setError(null);
      const currentMarcajeId = marcajeId || localStorage.getItem('marcajeId');
      const userId = localStorage.getItem('userId');
      console.log('currentMarcajeId:', currentMarcajeId);
  
      if (!currentMarcajeId) {
        throw new Error('No se encontró el marcaje activo');
      }
      
      if (!userId) {
        throw new Error('No se encontró la información del usuario');
      }
  
      const endTime = new Date();
      const horaFin = endTime.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });
      
      let duration = '00:00:00';
      if (startTime) {
        const durationInSeconds = Math.floor((endTime - startTime) / 1000);
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = durationInSeconds % 60;
        duration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      }
  
      const payload = {
        idMarcaje: parseInt(currentMarcajeId),
        HoraFin: horaFin,
        sDuracion: duration
      };
  
      console.log('Payload para stop_marcaje:', payload);
      console.log('URL de la API:', `${apiUrl}stop_marcaje.php`);
  
      const response = await axios.post(`${apiUrl}stop_marcaje.php?userId=${userId}`, payload);
      console.log('Respuesta de stop_marcaje:', response.data);
  
      if (response.data.success) {
        setIsRunning(false);
        setStartTime(null);
        setMarcajeId(null);
        localStorage.removeItem('marcajeId');
      } else {
        throw new Error(response.data.error || 'Error al detener el marcaje');
      }
    } catch (error) {
      console.error('Error detallado al detener marcaje:', error);
      const errorMessage = error.response?.data?.error || error.message || 'Error al detener el marcaje';
      setError(errorMessage);
    }
  };

  const handleClick = async () => {
    try {
      if (isRunning) {
        await stopChrono();
      } else {
        await startChrono();
      }
    } catch (error) {
      console.error('Error en handleClick:', error);
      setError('Error al procesar la operación');
    }
  };

  useEffect(() => {
    const storedMarcajeId = localStorage.getItem('marcajeId');
    if (storedMarcajeId) {
      console.log('Recuperando marcaje guardado:', storedMarcajeId);
      setMarcajeId(storedMarcajeId);
      setIsRunning(true);
    }
  }, []);

  return (
    <div className="chrono-container">
      <div className="chrono-button-wrapper">
        {isRunning ? (
          <StopButton onClick={handleClick} />
        ) : (
          <StartButton onClick={handleClick} />
        )}
      </div>
      {error && (
        <div className="error-message">
          <p>{error}</p>
          <button 
            className="error-close"
            onClick={() => setError(null)}
          >
            ×
          </button>
        </div>
      )}
    </div>
  );
};

export default ChronoButton;