import React from 'react';
import "../styles/secsol.css"

const SolucionesLaborales = () => {
  const solutions = [
    {
      icon: '🍃',
      title: 'Gestión de Nómina',
      description: 'Optimiza tus procesos de nómina con nuestras soluciones automatizadas.'
    },
    {
      icon: '🖥️',
      title: 'Seguimiento de Tiempo',
      description: 'Monitorea y gestiona con precisión el tiempo y la asistencia de tus empleados.'
    },
    {
      icon: '☁️',
      title: 'Gestión de RRHH',
      description: 'Optimiza tus procesos de RRHH con nuestras soluciones integrales.'
    },
    {
      icon: '🔒',
      title: 'Seguimiento de Cumplimiento',
      description: 'Asegura que tu negocio cumpla con los requisitos normativos.'
    },
    {
      icon: 'ℹ️',
      title: 'Análisis de la Fuerza Laboral',
      description: 'Obtén información sobre tu fuerza laboral con nuestras herramientas de análisis avanzadas.'
    },
    {
      icon: '📈',
      title: 'Compromiso de los Empleados',
      description: 'Mejora la satisfacción y productividad de tus empleados con nuestras soluciones de compromiso.'
    }
  ];

  return (
    <div className="soluciones-laborales">
      <h1>Soluciones Laborales para Empresas</h1>
      <p className="subtitle">
        Explora nuestra suite integral de soluciones para elevar tus operaciones empresariales y la gestión de tu fuerza laboral.
      </p>
      <div className="solutions-grid">
        {solutions.map((solution, index) => (
          <div key={index} className="solution-card">
            <div className="solution-icon">{solution.icon}</div>
            <h3>{solution.title}</h3>
            <p>{solution.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SolucionesLaborales;