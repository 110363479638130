// import GoBack from "../components/GoBack";
import MaterialRequest from "../components/MatReq";
import '../styles/MaterialRequest.css';

const MaterialReq = () =>{
    return (
        <div className="main-material">
            <MaterialRequest />
        </div>
    );
};

export default MaterialReq;