import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import config from '../config';
import "../styles/staff.css";
import "../styles/modal.css";
import SearchComponent from './SearchComponent';
import Modal from './ModalStaff';
import ModalPermissions from './ModalPermissions';
import { useAuth } from '../AuthContext';
import { Circles } from 'react-loader-spinner';

const UserDataComponent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isPermissionsModalOpen, setIsPermissionsModalOpen] = useState(false);
  const [selectedPermissionsUser, setSelectedPermissionsUser] = useState(null);

  const { isAuthenticated, logout, getAuthToken } = useAuth();

  const environment = process.env.NODE_ENV || 'development';
  const apiUrl = `${config[environment].PHP_GET_STAFF_URL}`;
  const updateEstadoUrl = `${config[environment].PHP_UPDATE_ESTADO_URL}`;

  const fetchData = useCallback(async () => {
    if (!isAuthenticated) return;

    try {
      const token = getAuthToken();
      const userId = localStorage.getItem('userId');
      const response = await axios.get(`${apiUrl}?userId=${userId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setData(response.data);
      setFilteredData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 401) {
        setError('Fallo de autenticación. Por favor, inicie sesión de nuevo.');
        logout();
      } else {
        setError('No hay respuesta por parte del servidor, compruebe su conexión e inténtelo de nuevo.');
      }
      setLoading(false);
    }
  }, [apiUrl, isAuthenticated, logout, getAuthToken]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearch = (searchTerm) => {
    const filtered = data.filter((user) =>
      Object.values(user).some((value) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };

  const handleRowClick = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleKeyDown = (event, user) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleRowClick(user);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const openPermissionsModal = (user) => {
    setSelectedPermissionsUser(user);
    setIsPermissionsModalOpen(true);
    setIsModalOpen(false);
  };

  const closePermissionsModal = () => {
    setIsPermissionsModalOpen(false);
    setSelectedPermissionsUser(null);
  };

  const handleEstadoChange = async (user, newEstado) => {
    try {
      const token = getAuthToken();
      const userId = localStorage.getItem('userId');
      const response = await axios.post(updateEstadoUrl, {
        sUsuario: user.sUsuario,
        bEstado: newEstado ? 1 : 0,
        userId: userId
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      
      if (response.data.success) {
        const updatedData = data.map(item => 
          item.sUsuario === user.sUsuario ? { ...item, bEstado: newEstado ? 1 : 0 } : item
        );
        setData(updatedData);
        setFilteredData(updatedData);
      } else {
        throw new Error('Error al actualizar el estado');
      }
    } catch (error) {
      console.error('Error al actualizar el estado:', error);
      if (error.response && error.response.status === 401) {
        setError('Fallo de autenticación. Por favor, inicie sesión de nuevo.');
        logout();
      } else {
        setError(`Error al actualizar el estado: ${error.message}`);
      }
    }
  };

  if (!isAuthenticated) return <div>Por favor, inicie sesión para ver esta página.</div>;
  if (loading) return (
    <div style={{ width: "100%", height: "90vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Circles height="80" width="80" color="#000000" ariaLabel="circles-loading" />
    </div>
  );
  if (error) return <div>{error}</div>;
  if (data.length === 0) return <div>No hay datos disponibles</div>;

  return (
    <div className='main-staff-fetch'>
      <h1 className='name-left'>Operarios</h1>
      <SearchComponent onSearch={handleSearch} />
      <div className='table-staff'>
        <div className='tr-staff header'>
          <div className='td-staff-item'>Usuario</div>
          <div className='td-staff-item'>Nombre Completo</div>
          <div className='td-staff-item'>Categoría</div>
          <div className='td-staff-item'>Email</div>
          <div className='td-staff-item'>Estado</div>
        </div>
        <div className='tbody-staff'>
          {filteredData.map((user) => (
            <div 
              key={user.sUsuario} 
              className='tr-staff'
              role="button"
              tabIndex={0}
              onKeyDown={(event) => handleKeyDown(event, user)}
            >
              <div className='td-staff-item' onClick={() => handleRowClick(user)}>{user.sUsuario}</div>
              <div className='td-staff-item' onClick={() => handleRowClick(user)}>{user.sNombre_Completo}</div>
              <div className='td-staff-item' onClick={() => handleRowClick(user)}>{user.categoria}</div>
              <div className='td-staff-item' onClick={() => handleRowClick(user)}>{user.sEmail}</div>
              <div className='td-staff-item'>
                <input
                  type="checkbox"
                  checked={user.bEstado === 1}
                  onChange={(e) => handleEstadoChange(user, e.target.checked)}
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal 
        isOpen={isModalOpen} 
        onClose={closeModal} 
        user={selectedUser}
        onOpenPermissions={openPermissionsModal}
      />
      <ModalPermissions 
        isOpen={isPermissionsModalOpen} 
        onClose={closePermissionsModal} 
        user={selectedPermissionsUser}
      />
    </div>
  );
};

export default UserDataComponent;