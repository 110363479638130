import React from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Home from "./sites/Home";
import Saas from "./sites/smalSaas";
import Login from './sites/Login';
import RegisterStaff from './sites/RegisterStaff';
import RegisterClient from './sites/RegisterClient';
import WorkdayLog from './sites/WorkdayLog';
import MaterialRequests from './sites/MaterialReq';
import TermsService from './components/TermsService';
import PrivacyPolicy from './components/PrivacyPolicy';
import Unauthorized from './sites/Unauthorized';
import Admin from './sites/Admin';
import Clients from './sites/Clients';
import Staff from './sites/Staff';
import Suppliers from './sites/Suppliers';
import WorkLog from './sites/WorkLog';
import Supplies from './sites/Supplies';
import EditPermissions from './components/ModalPermissions';
import CreateSchedule from './sites/CreateSchedule';
import RequestsList from './components/RequestsList';

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const { isAuthenticated, checkRoutePermission } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!checkRoutePermission(location.pathname)) {
    return <Navigate to="/unauthorized" replace />;
  }

  return <Component {...rest} />;
};

const AppRoutes = () => {
  return (
    <Routes>
      {/* Paginas de inicio */}
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/terms" element={<TermsService />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      {/* Paginas del Trabajador */}
      <Route path="/Saas" element={<ProtectedRoute element={Saas} />} />
      <Route path="/Saas/workdaylog" element={<ProtectedRoute element={WorkdayLog} />} />
      <Route path="/Saas/materialrequests" element={<ProtectedRoute element={MaterialRequests} />} />
      {/* Paginas del Administrador */}
      <Route path="/Saas/admin" element={<ProtectedRoute element={Admin} />} />
      <Route path="/Saas/admin/registerstaff" element={<ProtectedRoute element={RegisterStaff} />} />
      <Route path="/Saas/admin/registerclient" element={<ProtectedRoute element={RegisterClient} />} />
      <Route path='/Saas/admin/management/clients' element={<ProtectedRoute element={Clients} />} />
      <Route path="/Saas/admin/management/operarios" element={<ProtectedRoute element={Staff} />} />
      <Route path="/Saas/admin/management/proveedores" element={<ProtectedRoute element={Suppliers} />} />
      <Route path="/Saas/admin/presencecontrol/worklog" element={<ProtectedRoute element={WorkLog} />} />
      <Route path="/Saas/admin/materials/supplies" element={<ProtectedRoute element={Supplies} />} />
      <Route path="/Saas/admin/editpermissions" element={<ProtectedRoute element={EditPermissions} />} />
      <Route path='/Saas/admin/presencecontrol/schedules' element={<ProtectedRoute element={CreateSchedule} />} />
      <Route path='/Saas/admin/materials/requests' element={<ProtectedRoute element={RequestsList} />} />
      {/* Pagina de Unauthorized */}
      <Route path="/unauthorized" element={<Unauthorized/>} />
    </Routes>
  );
};

export default AppRoutes;