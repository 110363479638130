import GoBack from "../components/GoBack";

const Unauthorized = () => {
    return (
        <div style={{margin: "2rem"}}>
            <h1>Acceso denegado</h1>
            <p>No tienes permiso para acceder a esta pagina.</p>
            <GoBack />
        </div>
    );
};

export default Unauthorized;