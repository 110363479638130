import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import "../styles/createschedule.css";
import { Circles } from 'react-loader-spinner';

const ScheduleCreationModal = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        idUsuario: '',
        idCliente: '',
        iMes: '',
        iDia: '',
        dHoraInicio: '',
        dHoraFin: ''
    });
    const [users, setUsers] = useState([]);
    const [clients, setClients] = useState([]);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [daysInMonth, setDaysInMonth] = useState([]);

    const environment = process.env.NODE_ENV || 'development';
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(config[environment].PHP_GET_USERS_AND_CLIENTS_URL);

                if (response.data && Array.isArray(response.data.users) && Array.isArray(response.data.clients)) {
                    setUsers(response.data.users);
                    setClients(response.data.clients);
                } else {
                    throw new Error('Received invalid data format');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to load data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        if (isOpen) {
            fetchData();
        }
    }, [environment, isOpen]);

    useEffect(() => {
        if (formData.iMes) {
            const days = new Date(currentYear, parseInt(formData.iMes), 0).getDate();
            setDaysInMonth(Array.from({ length: days }, (_, i) => i + 1));
        } else {
            setDaysInMonth([]);
        }
    }, [formData.iMes, currentYear]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
            ...(name === 'iMes' ? { iDia: '' } : {})
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const apiUrl = config[environment].PHP_CREATE_SCHEDULE_URL;

        try {
            const response = await axios.post(apiUrl, formData);
            if (response.data.success) {
                setMessage('Schedule created successfully');
                setFormData({
                    idUsuario: '',
                    idCliente: '',
                    iMes: '',
                    iDia: '',
                    dHoraInicio: '',
                    dHoraFin: ''
                });
                setTimeout(() => {
                    onClose();
                }, 2000);
            } else {
                setMessage('Failed to create schedule');
            }
        } catch (error) {
            console.error('Error creating schedule:', error);
            setMessage('An error occurred while creating the schedule');
        }
    };

    if (!isOpen) return null;

    const months = [
        { value: "1", name: "Enero" },
        { value: "2", name: "Febrero" },
        { value: "3", name: "Marzo" },
        { value: "4", name: "Abril" },
        { value: "5", name: "Mayo" },
        { value: "6", name: "Junio" },
        { value: "7", name: "Julio" },
        { value: "8", name: "Agosto" },
        { value: "9", name: "Septiembre" },
        { value: "10", name: "Octubre" },
        { value: "11", name: "Noviembre" },
        { value: "12", name: "Diciembre" }
    ];

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Create Schedule</h2>
                {loading ? (
                    <div style={{ width: "100%", height: "90vh", display: "flex", justifyContent: "center", alignContent: "center", flexWrap: "wrap" }}>
                        <Circles
                            height="80"
                            width="80"
                            color="#000000"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>
                ) : error ? (
                    <div>Error: {error}</div>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="idUsuario">User:</label>
                            <select
                                id="idUsuario"
                                name="idUsuario"
                                value={formData.idUsuario}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select a user</option>
                                {users.map((user) => (
                                    <option key={user.idUsuario} value={user.idUsuario}>
                                        {user.sNombre_Completo}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="idCliente">Client:</label>
                            <select
                                id="idCliente"
                                name="idCliente"
                                value={formData.idCliente}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select a client</option>
                                {clients.map((client) => (
                                    <option key={client.idCliente} value={client.idCliente}>
                                        {client.sCliente}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="iMes">Month:</label>
                            <select
                                id="iMes"
                                name="iMes"
                                value={formData.iMes}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select a month</option>
                                {months.map(month => (
                                    <option key={month.value} value={month.value}>
                                        {month.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="iDia">Day:</label>
                            <select
                                id="iDia"
                                name="iDia"
                                value={formData.iDia}
                                onChange={handleChange}
                                required
                                disabled={!formData.iMes}
                            >
                                <option value="">Select a day</option>
                                {daysInMonth.map(day => (
                                    <option key={day} value={day}>
                                        {day}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="dHoraInicio">Start Time:</label>
                            <input
                                type="time"
                                id="dHoraInicio"
                                name="dHoraInicio"
                                value={formData.dHoraInicio}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="dHoraFin">End Time:</label>
                            <input
                                type="time"
                                id="dHoraFin"
                                name="dHoraFin"
                                value={formData.dHoraFin}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="modal-actions">
                            <button type="submit">Create Schedule</button>
                            <button type="button" onClick={onClose}>Cancel</button>
                        </div>
                    </form>
                )}
                {message && <p className="message">{message}</p>}
            </div>
        </div>
    );
};

export default ScheduleCreationModal;