import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import '../styles/modal.css';
import { useAuth } from '../AuthContext';

const ModalRequest = ({ isOpen, onClose, request, onUpdateRequest }) => {
  const [editingUnits, setEditingUnits] = useState(false);
  const [units, setUnits] = useState(request ? request.iUnidades : 0);
  const [tempUnits, setTempUnits] = useState(units);
  const [error, setError] = useState('');

  const { getAuthToken } = useAuth();
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    if (request) {
      setUnits(request.iUnidades);
      setTempUnits(request.iUnidades);
    }
  }, [request]);

  if (!isOpen || !request) return null;

  const handleUpdateStatus = (newStatus) => {
    onUpdateRequest(request.idMovimiento, { estado: newStatus, userId });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const handleUnitsDoubleClick = () => {
    setEditingUnits(true);
    setTempUnits(units);
  };

  const handleUnitsChange = (e) => {
    setTempUnits(e.target.value);
  };

  const handleUnitsSave = async () => {
    try {
      const environment = process.env.NODE_ENV || 'development';
      const updateUrl = `${config[environment].PHP_UPDATE_MOVIMIENTOS_URL}`;
      const token = getAuthToken();

      const response = await axios.post(updateUrl, {
        idMovimiento: request.idMovimiento,
        iUnidades: tempUnits,
        userId
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.data.success) {
        setUnits(tempUnits);
        onUpdateRequest(request.idMovimiento, { iUnidades: tempUnits, userId });
        setEditingUnits(false);
        setError('');
      } else {
        throw new Error(response.data.message || 'Error al actualizar las unidades');
      }
    } catch (error) {
      console.error('Error al actualizar las unidades:', error);
      setError('Error al actualizar las unidades. Por favor, inténtelo de nuevo.');
    }
  };

  const handleUnitsCancel = () => {
    setEditingUnits(false);
    setTempUnits(units);
    setError('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleUnitsSave();
    }
  };

  return (
    <div className="modal-overlay-request">
      <div className="modal-content-request">
        <div className="modal-content-request-header">
          <h2>Detalles del Pedido</h2>
          <button className="modal-close-request" onClick={onClose}>&times;</button>
        </div>
        <div className="modal-content-request-body">
          <p><strong>Nº Pedido:</strong> {request.idMovimiento}</p>
          <p><strong>Nombre:</strong> {request.nombre}</p>
          <p><strong>Estado:</strong> {request.estado}</p>
          <p><strong>Ultima Actualización:</strong> {formatDate(request.dFechaMovimiento)}</p>
          <p>
            <strong>Unidades: </strong>
            {editingUnits ? (
              <>
                <input
                  type="number"
                  value={tempUnits}
                  onChange={handleUnitsChange}
                  onKeyPress={handleKeyPress}
                  autoFocus
                />
                <button onClick={handleUnitsSave} className="save-button">Guardar</button>
                <button onClick={handleUnitsCancel} className="cancel-button">Cancelar</button>
              </>
            ) : (
              <span onDoubleClick={handleUnitsDoubleClick}>{units}</span>
            )}
          </p>
          <p><strong>Operario:</strong> {request.operario}</p>
          <p><strong>Cliente:</strong> {request.sCliente}</p>
        </div>
        <div className="modal-content-request-footer">
          <button onClick={() => handleUpdateStatus('Enviado')}>Enviado</button>
          <button onClick={() => handleUpdateStatus('En preparación')}>En preparación</button>
          <button onClick={() => handleUpdateStatus('En reparto')}>En reparto</button>
          <button onClick={() => handleUpdateStatus('Entregado')}>Entregado</button>
        </div>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default ModalRequest;