import GoBack from '../components/GoBack';
import TestRelations from '../components/testRelations';

const Suppliers = () => {
    return (
        <div>
            <GoBack />
            <TestRelations id={1} tipo="usuario"/>
        </div>
    );
};

export default Suppliers;