import React from "react";
import ChoosingPage from "../components/ChoosingPage";
import "../styles/saas.css";

const Main = () => {
    return (
        <div className="main-saas">
            <ChoosingPage />
        </div>
    );
};

export default Main;