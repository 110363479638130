import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import config from './config';
import { useAuth } from './AuthContext';

const TranslationContext = createContext();

export const useTranslation = () => useContext(TranslationContext);

export const TranslationProvider = ({ children }) => {
    const [translations, setTranslations] = useState({});
    const [loading, setLoading] = useState(true);
    const { isAuthenticated, getAuthToken } = useAuth();
    const environment = process.env.NODE_ENV || 'development';

    useEffect(() => {
        const fetchTranslations = async () => {
            if (!isAuthenticated) {
                setLoading(false);
                return;
            }

            const empresaId = localStorage.getItem('empresaId');
            if (!empresaId) {
                console.error('ID de empresa no encontrado');
                setLoading(false);
                return;
            }

            try {
                const token = getAuthToken();
                const response = await axios.get(`${config[environment].API_BASE_URL}get_translation_file.php`, {
                    params: { empresaId },
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                if (response.data && response.data.file) {
                    const translationResponse = await axios.get(`${config[environment].API_BASE_URL}traducciones/${response.data.file}`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    setTranslations(translationResponse.data);
                } else {
                    console.error('Archivo de traducciones no encontrado');
                }
            } catch (error) {
                console.error('Error al cargar las traducciones:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTranslations();
    }, [isAuthenticated, environment, getAuthToken]);

    const translate = (key) => {
        return translations[key] || key;
    };

    return (
        <TranslationContext.Provider value={{ t: translate, loading }}>
            {children}
        </TranslationContext.Provider>
    );
};