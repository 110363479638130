import React, { useState } from 'react';
import axios from 'axios';
import config from '../config';
import '../styles/editClientForm.css';
import { useAuth } from '../AuthContext';

const EditClientForm = ({ client, onEditComplete }) => {
    const [formData, setFormData] = useState({
        ...client,
        TCliente: client.TCliente || ''
    });
    const [error, setError] = useState(null);
    const { getAuthToken } = useAuth();

    const environment = process.env.NODE_ENV || 'development';
    const updateClientUrl = `${config[environment].PHP_UPDATE_CLIENT_URL}`;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        try {
            const token = getAuthToken();
            const userId = localStorage.getItem('userId');
            const response = await axios.post(updateClientUrl, 
                { ...formData, userId },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            if (response.data.success) {
                onEditComplete(formData);
            } else {
                throw new Error(response.data.message || 'Error al actualizar el cliente');
            }
        } catch (error) {
            console.error('Error al actualizar el cliente:', error);
            setError('Error al actualizar el cliente. Por favor, inténtelo de nuevo.');
        }
    };

    return (
        <form onSubmit={handleSubmit} className="edit-client-form">
            <h2>Editar Cliente</h2>
            <div className="form-group-1">
                <input type="text" id="sCliente" name="sCliente" value={formData.sCliente} onChange={handleChange} required />
            </div>
            <div className="form-group-2">
                <div className='form-group-2-1'>
                    <div className="form-group-0">
                        <label htmlFor="sNIF">NIF:</label>
                        <input type="text" id="sNIF" name="sNIF" value={formData.sNIF} onChange={handleChange} required />
                    </div>
                    <div className="form-group-0">
                        <label htmlFor="TCliente">Tipo de Cliente:</label>
                        <select
                            name="TCliente"
                            value={formData.TCliente}
                            onChange={handleChange}
                            required
                            className="form-select"
                        >
                            <option value="" disabled>Selecciona un tipo de cliente</option>
                            <option value="1">Empresa</option>
                            <option value="2">Particular</option>
                        </select>
                    </div>
                    <div className="form-group-0">
                        <label htmlFor="sEmail">Email:</label>
                        <input type="email" id="sEmail" name="sEmail" value={formData.sEmail} onChange={handleChange} required />
                    </div>
                    <div className="form-group-0">
                        <label htmlFor="sTipoVia">Tipo de Vía:</label>
                        <input type="text" id="sTipoVia" name="sTipoVia" value={formData.sTipoVia} onChange={handleChange} />
                    </div>
                    <div className="form-group-0">
                        <label htmlFor="sDireccion">Dirección:</label>
                        <input type="text" id="sDireccion" name="sDireccion" value={formData.sDireccion} onChange={handleChange} required />
                    </div>
                    <div className="form-group-0">
                        <label htmlFor="sPoblacion">Población:</label>
                        <input type="text" id="sPoblacion" name="sPoblacion" value={formData.sPoblacion} onChange={handleChange} required />
                    </div>
                </div>
                <div className="form-group-2-2">
                    <div className="form-group-0">
                        <label htmlFor="sProvincia">Provincia:</label>
                        <input type="text" id="sProvincia" name="sProvincia" value={formData.sProvincia} onChange={handleChange} required />
                    </div>
                    <div className="form-group-0">
                        <label htmlFor="sCP">Código Postal:</label>
                        <input type="text" id="sCP" name="sCP" value={formData.sCP} onChange={handleChange} required />
                    </div>
                    <div className="form-group-0">
                        <label htmlFor="sTel1">Teléfono 1:</label>
                        <input type="text" id="sTel1" name="sTel1" value={formData.sTel1} onChange={handleChange} />
                    </div>
                    <div className="form-group-0">
                        <label htmlFor="sTel2">Teléfono 2:</label>
                        <input type="text" id="sTel2" name="sTel2" value={formData.sTel2} onChange={handleChange} />
                    </div>
                    <div className="form-group-0">
                        <label htmlFor="sWeb">Sitio Web:</label>
                        <input type="text" id="sWeb" name="sWeb" value={formData.sWeb} onChange={handleChange} />
                    </div>
                    <div className="form-group-0">
                        <label htmlFor="sContacto">Contacto:</label>
                        <input type="text" id="sContacto" name="sContacto" value={formData.sContacto} onChange={handleChange} />
                    </div>
                </div>
            </div>
            {error && <p className="error-message">{error}</p>}
            <div className="form-actions">
                <button type="submit">Guardar Cambios</button>
                <button type="button" onClick={() => onEditComplete(client)}>Cancelar</button>
            </div>
        </form>
    );
};

export default EditClientForm;