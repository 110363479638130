import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import config from '../config';
import { Circles } from 'react-loader-spinner';
import SearchComponent from './SearchComponent';
import Modal from './ModalItem';
import '../styles/supplies.css';
import { useAuth } from '../AuthContext';

const SuppliesList = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);
    const [error, setError] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowLimit, setRowLimit] = useState(25);

    const { getAuthToken } = useAuth();
    const userId = localStorage.getItem('userId');

    const environment = process.env.NODE_ENV || 'development';
    const apiUrl = `${config[environment].PHP_GET_ARTICULOS_URL}`;
    const updateEstadoUrl = `${config[environment].PHP_UPDATE_ESTADO_ARTICULO_URL}`;

    const fetchData = useCallback(async () => {
        try {
            const token = getAuthToken();
            if (!token) {
                throw new Error('No se encontró el token de autenticación');
            }

            const response = await axios.get(`${apiUrl}?userId=${userId}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.data.success && Array.isArray(response.data.data)) {
                setData(response.data.data);
                setFilteredData(response.data.data);
            } else {
                throw new Error(response.data.message || 'Error al obtener los datos');
            }
        } catch (error) {
            console.error('Detalles del error:', error.response || error);
            setError(error.response?.data?.message || error.message || 'Error al cargar los datos');
        } finally {
            setLoading(false);
        }
    }, [apiUrl, userId, getAuthToken]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSearch = (term) => {
        const filtered = data.filter((supply) =>
            Object.values(supply).some((value) =>
                value.toString().toLowerCase().includes(term.toLowerCase())
            )
        );
        setFilteredData(filtered);
        setCurrentPage(1);
    };

    const handleRowClick = (supply) => {
        setSelectedItem(supply);
        setIsModalOpen(true);
    };

    const handleKeyDown = (event, supply) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleRowClick(supply);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedItem(null);
    };

    const updateSupply = async (updatedSupply) => {
        try {
            const token = getAuthToken();
            const updateUrl = `${config[environment].PHP_UPDATE_SUPPLIES_URL}`;
            
            // Comparar con el artículo original
            const originalSupply = data.find(item => item.idArticulo === updatedSupply.idArticulo);
            if (JSON.stringify(originalSupply) === JSON.stringify(updatedSupply)) {
                console.log('No se detectaron cambios en el artículo');
                closeModal();
                return;
            }

            const response = await axios.post(updateUrl, 
                { ...updatedSupply, userId },
                {
                    headers: { 
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.success) {
                const updatedData = data.map(item => 
                    item.idArticulo === updatedSupply.idArticulo ? updatedSupply : item
                );
                setData(updatedData);
                setFilteredData(updatedData);
                closeModal();
            } else {
                throw new Error(response.data.message || 'Error al actualizar el artículo');
            }
        } catch (error) {
            console.error('Error al actualizar el artículo:', error);
            throw error;
        }
    };

    const handleEstadoChange = async (supply, newEstado) => {
        try {
            const token = getAuthToken();
            const response = await axios.post(updateEstadoUrl, {
                idArticulo: supply.idArticulo,
                bEstado: newEstado ? 1 : 0,
                userId: userId
            }, {
                headers: { 
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.success) {
                const updatedData = data.map(item => 
                    item.idArticulo === supply.idArticulo ? {...item, bEstado: newEstado} : item
                );
                setData(updatedData);
                setFilteredData(updatedData);
            } else {
                throw new Error(response.data.message || 'Error al actualizar el estado');
            }
        } catch (error) {
            console.error('Error al actualizar el estado:', error);
            setError(`Error al actualizar el estado: ${error.message}`);
        }
    };

    const handleRowLimitChange = (event) => {
        setRowLimit(Number(event.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    if (loading) {
        return (
            <div style={{ width: "100%", height: "90vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Circles height="80" width="80" color="#000000" ariaLabel="circles-loading" />
            </div>
        );
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    if (data.length === 0) {
        return <div>No hay artículos...</div>;
    }

    const totalPages = Math.ceil(filteredData.length / rowLimit);
    const startIndex = (currentPage - 1) * rowLimit;
    const endIndex = startIndex + rowLimit;
    const currentItems = filteredData.slice(startIndex, endIndex);

    return (
        <div className='main-items-fetch'>
            <h1 className='name-left'>Artículos</h1>
            <div className='filters'>
                <SearchComponent onSearch={handleSearch} placeholder="Buscar..." />
                <select 
                    value={rowLimit} 
                    onChange={handleRowLimitChange}
                    className="row-limit-select"
                >
                    <option value={25}>25 filas</option>
                    <option value={50}>50 filas</option>
                    <option value={100}>100 filas</option>
                </select>
            </div>
            <div className='table-items'>
                <div className='tr-items header'>
                    <div className='td-items-item'>Nombre Corto</div>
                    <div className='td-items-item'>Nombre Largo</div>
                    <div className='td-items-item'>Estado</div>
                </div>
                <div className='tbody-items'>
                    {currentItems.map((supply) => (
                        <div
                            key={supply.idArticulo}
                            className='tr-items'
                            tabIndex={0}
                            role="button"
                            onKeyDown={(event) => handleKeyDown(event, supply)}
                        >
                            <div className='td-items-item' onClick={() => handleRowClick(supply)}>{supply.sArticuloCorto}</div>
                            <div className='td-items-item' onClick={() => handleRowClick(supply)}>{supply.sArticuloLargo}</div>
                            <div className='td-items-item'>
                                <input
                                    type="checkbox"
                                    checked={supply.bEstado}
                                    onChange={(e) => handleEstadoChange(supply, e.target.checked)}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="pagination">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Anterior
                </button>
                <span>Página {currentPage} de {totalPages}</span>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Siguiente
                </button>
            </div>
            <div>Mostrando {currentItems.length} de {filteredData.length} artículos</div>
            {isModalOpen && 
                <Modal 
                    isOpen={isModalOpen} 
                    onClose={closeModal} 
                    supply={selectedItem}
                    onUpdate={updateSupply}
                />
            }
        </div>
    );
};

export default SuppliesList;