import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import GoBack from "../components/GoBack";
import StrtStp from "../components/Crono";
import "../styles/worklog.css";
import ClientChoosing from "../components/ClientChoosing";
import Client from "../components/Client";

const WorkLog = () => {
    const [clientName, setClientName] = useState('');
    const [selectedClientId, setSelectedClientId] = useState(localStorage.getItem('selectedClientId') || '1');

    const environment = process.env.NODE_ENV || 'development';
    const apiUrl = config[environment].API_BASE_URL;

    useEffect(() => {
        const fetchClientName = async () => {
            try {
                const response = await axios.get(`${apiUrl}/get_client_w.php?id=${selectedClientId}`);
                setClientName(response.data.sCliente);
            } catch (error) {
                console.error('Error al obtener el nombre del cliente:', error);
            }
        };

        fetchClientName();
    }, [selectedClientId, apiUrl]);

    const handleClientChange = (newClientId) => {
        setSelectedClientId(newClientId);
        localStorage.setItem('selectedClientId', newClientId);
    };

    return (
        <div className="worklog">
            <GoBack />
            <Client clientName={clientName} />
            <ClientChoosing onClientChange={handleClientChange} />
            <StrtStp />
        </div>
    );
};

export default WorkLog;