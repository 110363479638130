import React from "react";
import "../styles/privpol.css";
import GoBack from "../components/GoBack";

const PrivacyPolicy = () => {
  return (
    <div className="PriPo">
      <h1>Política de Privacidad de WorkSuite</h1>

      <p>
        <em>Última actualización: 15 de agosto de 2024</em>
      </p>

      <h2>1. Introducción</h2>
      <p>
        WorkSuite es un servicio de software como servicio (SaaS) diseñado para la gestión de
        empresas. Esta política de privacidad explica cómo
        recopilamos, usamos y protegemos la información personal de los
        empleados de nuestros clientes.
      </p>

      <h2>2. Información que recopilamos</h2>
      <p>
        WorkSuite recopila los siguientes datos personales de los empleados:
      </p>
      <ul>
        <li>Correo electrónico</li>
        <li>Número de teléfono</li>
        <li>Número de la Seguridad Social</li>
        <li>Detalles del Documento Nacional de Identidad (DNI)</li>
        <li>Datos de ubicación</li>
        <li>Información relacionada con el trabajo</li>
      </ul>
      <p>
        Esta información se recopila a través de formularios y seguimiento GPS.
      </p>

      <h2>3. Uso de la información</h2>
      <p>
        La información se recopila y utiliza únicamente para que las empresas
        administren a sus propios empleados. Todo el procesamiento de datos se
        realiza de acuerdo con la ley española de protección de datos.
      </p>

      <h2>4. Compartir información</h2>
      <p>
        No compartimos su información personal con terceros, excepto cuando sea
        requerido por ley. Sus datos no se transfieren fuera del Espacio
        Económico Europeo (EEE).
      </p>

      <h2>5. Retención de datos</h2>
      <p>
        Mantenemos sus datos solo mientras el contrato con su empresa esté
        activo. Los períodos de retención específicos son:
      </p>
      <ul>
        <li>
          Datos de contacto (correo electrónico, teléfono): Hasta 6 meses
          después de la finalización del contrato
        </li>
        <li>
          Número de Seguridad Social y DNI: Hasta 5 años después de la
          finalización del contrato (requisito legal)
        </li>
        <li>Datos de ubicación: Hasta 1 año después de la recopilación</li>
        <li>
          Información relacionada con el trabajo: Hasta 3 años después de la
          finalización del contrato
        </li>
      </ul>

      <h2>6. Seguridad de los datos</h2>
      <p>
        Tomamos en serio la seguridad de sus datos. Algunas de nuestras medidas
        de seguridad incluyen:
      </p>
      <ul>
        <li>
          Encriptación de contraseñas y números de Seguridad Social mediante
          algoritmos de hash
        </li>
        <li>Protección contra ataques de inyección SQL y XSS</li>
        <li>Acceso restringido a los datos solo para personal autorizado</li>
        <li>Copias de seguridad regulares y cifradas</li>
        <li>Auditorías de seguridad periódicas</li>
      </ul>

      <h2>7. Sus derechos</h2>
      <p>Usted tiene derecho a:</p>
      <ul>
        <li>Acceder a sus datos personales</li>
        <li>Solicitar la rectificación de datos inexactos</li>
        <li>Solicitar la eliminación parcial o total de sus datos</li>
      </ul>
      <p>
        Para ejercer estos derechos, contacte primero con su empleador. Si el
        problema no se resuelve, puede contactarnos directamente.
      </p>

      <h2>8. Permisos de GPS</h2>
      <p>
        Para utilizar WorkSuite, es necesario conceder permisos de GPS en su
        dispositivo. Ningún otro servicio o complemento de terceros tiene acceso
        a sus datos sensibles.
      </p>

      <h2>9. Restricción de edad</h2>
      <p>
        WorkSuite no está diseñado para personas menores de 18 años. No se
        permite el uso o acceso al servicio por parte de menores de edad.
      </p>

      <h2>10. Cambios en la política de privacidad</h2>
      <p>
        Cualquier cambio en esta política de privacidad se notificará con al
        menos un mes de antelación a través de los canales acordados con su
        empresa.
      </p>

      <h2>11. Contacto</h2>
      <p>
        Si tiene preguntas o inquietudes sobre esta política de privacidad, por
        favor:
      </p>
      <ol>
        <li>Contacte primero con su empleador</li>
        <li>
          Si el problema no se resuelve, contáctenos a través de:
          <ul>
            <li>Correo electrónico: soporte@infoprol.es</li>
            <li>Teléfono proporcionado a la empresa</li>
          </ul>
        </li>
      </ol>

      <p>
        Al utilizar WorkSuite, usted acepta los términos de esta política de
        privacidad.
      </p>
      <GoBack />
    </div>
  );
};

export default PrivacyPolicy;
