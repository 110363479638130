import UsersClients from '../components/UsersClients';
import GoBack from '../components/GoBack';
import AddClientButton from '../components/AddClientButton';

const Clients = () => {
    return (
        <div className="main-clients">
            <GoBack />
            <AddClientButton />
            <UsersClients />
        </div>
    );
};

export default Clients;