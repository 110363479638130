import React from 'react';
import "../styles/header.css";
import Logout from './LogOut';

const Header = () => {
    return (
        <nav className='nav-header'>
            <div className='lg'>
                
            </div>
            <div className="nh">
                <a href="/" className="t1">WorkSuite</a>
            </div>
            <div className="nt">
                <Logout />
            </div>
        </nav>
    )
}

export default Header;