import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import config from '../config';
import 'react-datepicker/dist/react-datepicker.css';
import { format, startOfDay, parseISO } from 'date-fns';
import "../styles/workloglist.css";
import FilterTable from './Filters';
import { Circles } from 'react-loader-spinner';
import { useAuth } from '../AuthContext';

const AdminMarcajesComponent = () => {
  const [marcajes, setMarcajes] = useState([]);
  const [filteredMarcajes, setFilteredMarcajes] = useState([]);
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const [endDate, setEndDate] = useState(startOfDay(new Date()));
  const [ignoreDateFilter, setIgnoreDateFilter] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedClient, setSelectedClient] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [rowLimit, setRowLimit] = useState(25);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDuration, setTotalDuration] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedMarcajes, setSelectedMarcajes] = useState({});

  const environment = process.env.NODE_ENV || 'development';
  const apiUrl = config[environment].API_BASE_URL;

  const { getAuthToken } = useAuth();
  const userId = localStorage.getItem('userId');

  const fetchData = useCallback(async () => {
    try {
      const token = getAuthToken();
      const [marcajesResponse, usersResponse, clientsResponse] = await Promise.all([
        axios.get(`${apiUrl}get_marcajes.php?userId=${userId}`, { headers: { 'Authorization': `Bearer ${token}` } }),
        axios.get(`${apiUrl}get_users.php?userId=${userId}`, { headers: { 'Authorization': `Bearer ${token}` } }),
        axios.get(`${apiUrl}get_clients.php?userId=${userId}`, { headers: { 'Authorization': `Bearer ${token}` } })
      ]);

      setMarcajes(marcajesResponse.data);
      setUsers(usersResponse.data);
      setClients(clientsResponse.data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('No hay respuesta por parte del servidor, compruebe su conexión e inténtelo de nuevo.');
      setLoading(false);
    }
  }, [apiUrl, userId, getAuthToken]);

  const applyFilters = useCallback(() => {
    let filtered = marcajes;

    if (!ignoreDateFilter) {
      filtered = filtered.filter((marcaje) => {
        const marcajeDate = parseISO(marcaje.dFecha);
        return marcajeDate >= startOfDay(startDate) && marcajeDate <= startOfDay(endDate);
      });
    }

    if (selectedUser) {
      filtered = filtered.filter((marcaje) => marcaje.idUsuario === selectedUser);
    }

    if (selectedClient) {
      filtered = filtered.filter((marcaje) => marcaje.idCliente === selectedClient);
    }

    if (searchTerm) {
      filtered = filtered.filter((marcaje) =>
        Object.values(marcaje).some((value) =>
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    setFilteredMarcajes(filtered);

    const totalSeconds = filtered.reduce((total, marcaje) => {
      if (marcaje.sDuracion) {
        const [hours, minutes, seconds] = marcaje.sDuracion.split(':').map(Number);
        return total + hours * 3600 + minutes * 60 + seconds;
      }
      return total;
    }, 0);

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    setTotalDuration(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
  }, [marcajes, startDate, endDate, ignoreDateFilter, selectedUser, selectedClient, searchTerm]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    applyFilters();
    setCurrentPage(1);
  }, [applyFilters]);

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleIgnoreDateFilter = () => {
    setIgnoreDateFilter(!ignoreDateFilter);
  };

  const handleUserFilter = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleClientFilter = (event) => {
    setSelectedClient(event.target.value);
  };

  const handleRowLimitChange = (event) => {
    setRowLimit(Number(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    const newSelectedMarcajes = {};
    filteredMarcajes.forEach(marcaje => {
      newSelectedMarcajes[marcaje.idMarcaje] = newSelectAll;
    });
    setSelectedMarcajes(newSelectedMarcajes);
  };

  const handleSelectMarcaje = (idMarcaje) => {
    setSelectedMarcajes(prev => ({
      ...prev,
      [idMarcaje]: !prev[idMarcaje]
    }));
  };

  if (loading) {
    return (
        <div style={{ width: "100%", height: "90vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Circles height="80" width="80" color="#000000" ariaLabel="circles-loading" />
        </div>
    );
}
  if (error) return <div>{error}</div>;

  const totalPages = Math.ceil(filteredMarcajes.length / rowLimit);
  const startIndex = (currentPage - 1) * rowLimit;
  const endIndex = startIndex + rowLimit;
  const currentMarcajes = filteredMarcajes.slice(startIndex, endIndex);

  return (
    <div className="admin-marcajes">
      <h1>Marcajes</h1>
      <FilterTable
        handleSearch={handleSearch}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        ignoreDateFilter={ignoreDateFilter}
        selectedUser={selectedUser}
        handleUserFilter={handleUserFilter}
        users={users}
        selectedClient={selectedClient}
        handleClientFilter={handleClientFilter}
        clients={clients}
        rowLimit={rowLimit}
        handleRowLimitChange={handleRowLimitChange}
      />
      <div className='filters-2'>
        <label>
          <input
            type="checkbox"
            checked={ignoreDateFilter}
            onChange={handleIgnoreDateFilter}
            className='input-checkbox'
          />
          Todas las fechas
        </label>
        <label>
          <input
            type='checkbox'
            checked={selectAll}
            onChange={handleSelectAll}
          />
          Seleccionar todos
        </label>
      </div>
      <div className="total-duration">
        <strong>Duración total:</strong> {totalDuration}
      </div>
      <table className='table-worklist'>
        <thead>
          <tr className='tr-worklist'>
            <th className='th-worklist'>Operario</th>
            <th className='th-worklist'>Cliente</th>
            <th className='th-worklist'>Hora Inicio</th>
            <th className='th-worklist'>Hora Fin</th>
            <th className='th-worklist'>Duración</th>
            <th className='th-worklist'>Fecha</th>
          </tr>
        </thead>
        <tbody>
          {currentMarcajes.map((marcaje) => (
            <tr key={marcaje.idMarcaje} className='tr-worklist'>
              <td className='td-worklist'>{marcaje.sNombre_Completo}</td>
              <td className='td-worklist'>{marcaje.sCliente}</td>
              <td className='td-worklist'>{marcaje.HoraIni}</td>
              <td className='td-worklist'>{marcaje.HoraFin || '-'}</td>
              <td className='td-worklist'>{marcaje.sDuracion || '-'}</td>
              <td className='td-worklist'>{format(new Date(marcaje.dFecha), 'dd-MM-yyyy')}</td>
              <td className='td-worklist-input'>
                <input
                  type='checkbox'
                  checked={selectedMarcajes[marcaje.idMarcaje] || false}
                  onChange={() => handleSelectMarcaje(marcaje.idMarcaje)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Anterior
        </button>
        <span>Página {currentPage} de {totalPages}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Siguiente
        </button>
      </div>
      <div>Mostrando {currentMarcajes.length} de {filteredMarcajes.length} marcajes</div>
    </div>
  );
};

export default AdminMarcajesComponent;