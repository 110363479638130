import Sidebar from '../components/SideBar';
import '../styles/admin.css';

const Admin = () => {
    return (
        <div className='admin-main'>
            <Sidebar />
        </div>
    );
};

export default Admin;