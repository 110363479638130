import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "../styles/login.css";
import "../styles/buttons.css";
import { useAuth } from '../AuthContext';
import config from '../config';

function Login() {
  const [sUsuario, setSUsuario] = useState('');
  const [sContrasenya, setSContrasenya] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();
  const environment = process.env.NODE_ENV || 'development';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    try {
      const response = await axios.post(config[environment].PHP_LOGIN_URL, {
        sUsuario,
        sContrasenya
      });

      if (response.data.token && response.data.user_id && response.data.role !== undefined && response.data.empresaId && response.data.sBaseDatos) {
        try {
          const permissions = await login(response.data.token, response.data.user_id, response.data.role, response.data.empresaId, response.data.sBaseDatos);
          
          if (permissions['/Saas'] && (permissions['/Saas'].iL || permissions['/Saas'].iC || permissions['/Saas'].iI || permissions['/Saas'].iM || permissions['/Saas'].iB)) {
            if (response.data.role === 'admin') {
              navigate('/Saas/admin');
            } else {
              navigate('/Saas');
            }
          } else {
            setMessage('No tiene permiso para acceder a esta página.');
          }
        } catch (loginError) {
          console.error('Error durante el proceso de inicio de sesión:', loginError);
          setMessage('Ha ocurrido un error inesperado durante el inicio de sesión. Inténtelo de nuevo.');
        }
      } else {
        console.error('Formato de respuesta inválido:', response.data);
        setMessage('Datos de inicio de sesión incompletos. Contacte con el administrador.');
      }
    } catch (error) {
      console.error('Detalles del error:', error);
      if (error.response) {
        console.error('Datos de la respuesta:', error.response.data);
        console.error('Estado de la respuesta:', error.response.status);
        setMessage(error.response.data.message || 'Inicio de sesión fallido. Credenciales incorrectas.');
      } else if (error.request) {
        console.error('No se recibió respuesta:', error.request);
        setMessage('Sin respuesta del servidor. Compruebe la conexión a Internet y vuelva a intentarlo.');
      } else {
        console.error('Mensaje de error:', error.message);
        setMessage('Ha ocurrido un error inesperado. Inténtelo de nuevo.');
      }
    }
  };

  return (
    <div className='main-form'>
      <form onSubmit={handleSubmit} className='LogForm'>
        <input
          type="text"
          value={sUsuario}
          onChange={(e) => setSUsuario(e.target.value)}
          placeholder="Usuario"
          required
          className="LogInp"
        />
        <input
          type="password"
          value={sContrasenya}
          onChange={(e) => setSContrasenya(e.target.value)}
          placeholder="Contraseña"
          required
          className="LogInp"
        />
        <button type="submit" className='LogButt'>Iniciar sesión</button>
        {message && <p className="error-message">{message}</p>}
      </form>
    </div>
  );
}

export default Login;