import React, { useState, useEffect } from "react";
import GoBack from "../components/GoBack";
import axios from "axios";
import config from "../config";
import "../styles/MaterialRequest.css";
import { Truck, Plus, X } from "lucide-react";

const ArticuloSelect = ({ onSelect, value, userId }) => {
  const [articulos, setArticulos] = useState([]);
  const [error, setError] = useState(null);
  const environment = process.env.NODE_ENV || "development";

  useEffect(() => {
    const fetchArticulos = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        const response = await axios.get(
          `${config[environment].API_BASE_URL}get_articulos.php`,
          {
            params: { userId },
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.success && Array.isArray(response.data.data)) {
          const articulosActivos = response.data.data.filter(
            (art) => art.bEstado === true
          );
          setArticulos(articulosActivos);
          setError(null);
        } else {
          throw new Error("Formato de respuesta inválido");
        }
      } catch (err) {
        console.error("Error al obtener artículos:", err);
        setError(
          "Error al cargar los artículos. Por favor, inténtelo de nuevo."
        );
        setArticulos([]);
      }
    };

    if (userId) {
      fetchArticulos();
    }
  }, [userId, environment]);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="articulo-select-container">
      <select
        value={value ? value.sArticuloCorto : ""}
        onChange={(e) => {
          const selectedArticulo = articulos.find(
            (a) => a.sArticuloCorto === e.target.value
          );
          onSelect(selectedArticulo);
        }}
        className="articulo-select"
      >
        <option value="">Seleccionar artículo</option>
        {articulos.map((articulo) => (
          <option key={articulo.idArticulo} value={articulo.sArticuloCorto}>
            {articulo.sArticuloCorto} - {articulo.sArticuloLargo}
          </option>
        ))}
      </select>
      {articulos.length === 0 && !error && (
        <div className="no-articulos">No hay artículos disponibles</div>
      )}
      {articulos.length > 0 && (
        <div className="articulos-count">
          Artículos disponibles: {articulos.length}
        </div>
      )}
    </div>
  );
};

const MaterialRequest = () => {
  const [selectedArticulo, setSelectedArticulo] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [uds, setQuantity] = useState("");
  const [addedArticles, setAddedArticles] = useState([]);
  const [message, setMessage] = useState("");
  const [clients, setClients] = useState([]);
  const [clientError, setClientError] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);

  const environment = process.env.NODE_ENV || "development";
  const apiUrl = config[environment].API_BASE_URL;

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(parseInt(storedUserId));
    }
  }, []);

  useEffect(() => {
    const fetchClients = async () => {
      if (!userId) return;

      setLoading(true);
      try {
        const authToken = localStorage.getItem("authToken");
        const response = await axios.get(`${apiUrl}get_relations.php`, {
          params: {
            userId: userId,
            id: userId,
            tipo: "usuario",
          },
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (Array.isArray(response.data) && response.data.length > 0) {
          setClients(response.data);
          setClientError(null);
        } else {
          setClientError("No se encontraron clientes asociados");
        }
      } catch (err) {
        console.error("Error al obtener clientes:", err);
        setClientError(
          "Error al cargar los clientes. Por favor, inténtelo de nuevo."
        );
        setClients([]);
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, [apiUrl, userId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedArticulo && uds) {
      const existingArticleIndex = addedArticles.findIndex(
        (art) => art.idArticulo === selectedArticulo.idArticulo
      );

      if (existingArticleIndex !== -1) {
        const updatedArticles = [...addedArticles];
        updatedArticles[existingArticleIndex].uds += parseInt(uds);
        setAddedArticles(updatedArticles);
      } else {
        setAddedArticles([
          ...addedArticles,
          { ...selectedArticulo, uds: parseInt(uds) },
        ]);
      }

      setSelectedArticulo(null);
      setQuantity("");
      setMessage("");
    } else {
      setMessage("Por favor, seleccione un artículo y cantidad");
    }
  };

  const handleSendRequest = async () => {
    // Validaciones iniciales
    if (!selectedClient) {
      setMessage("Por favor, seleccione un cliente");
      return;
    }
  
    if (!userId) {
      setMessage("Error: Usuario no identificado");
      return;
    }
  
    if (addedArticles.length === 0) {
      setMessage("Por favor, añada al menos un artículo");
      return;
    }
  
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      setMessage("Error: No hay token de autenticación");
      return;
    }
  
    const requestData = {
      userId: userId,
      idCliente: selectedClient.idCliente,
      movimientos: addedArticles.map((article) => ({
        idArticulo: article.idArticulo,
        iUnidades: article.uds,
      })),
    };
  
    const environment = process.env.NODE_ENV || "development";
    const url = `${config[environment].API_BASE_URL}insert_movimiento.php`;
  
    try {
      setLoading(true);
      const response = await axios({
        method: 'post',
        url: url,
        data: requestData,
        headers: {
          'X-Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (response.data.success) {
        setMessage("Solicitud enviada correctamente");
        // Limpiar el formulario después de un envío exitoso
        setAddedArticles([]);
        setSelectedClient(null);
      } else {
        throw new Error(response.data.message || "Error en la respuesta del servidor");
      }
    } catch (error) {
      console.error('Error al enviar la solicitud:', error);
      
      if (error.response?.status === 401) {
        setMessage("Error de autenticación. Por favor, vuelva a iniciar sesión.");
      } else {
        setMessage(
          error.response?.data?.message || 
          "Error al enviar la solicitud. Por favor, inténtelo de nuevo."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  if (!userId) {
    return (
      <div className="material-request-container">
        <GoBack />
        <div className="error-message">
          Error: Usuario no identificado. Por favor, inicie sesión nuevamente.
        </div>
      </div>
    );
  }

  return (
    <div className="material-request-container">
      <GoBack />
      <div className="material-request-content">
        <h2>Solicitud de Material</h2>

        <div className="material-request-form">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Artículo:</label>
              <ArticuloSelect
                onSelect={setSelectedArticulo}
                value={selectedArticulo}
                userId={userId}
              />
            </div>

            <div className="form-group">
              <label>Unidades:</label>
              <input
                type="number"
                value={uds}
                onChange={(e) => setQuantity(e.target.value)}
                min="1"
                max="99"
                required
              />
            </div>

            <button type="submit" className="add-button" disabled={loading}>
              <Plus size={20} /> Añadir
            </button>
          </form>

          <div className="form-group">
            <label>Cliente:</label>
            {clientError ? (
              <div className="error-message">{clientError}</div>
            ) : (
              <select
                value={selectedClient ? selectedClient.idCliente : ""}
                onChange={(e) => {
                  const client = clients.find(
                    (c) => c.idCliente === parseInt(e.target.value)
                  );
                  setSelectedClient(client);
                }}
                className="client-select"
              >
                <option value="">Seleccionar cliente</option>
                {clients.map((client) => (
                  <option key={client.idCliente} value={client.idCliente}>
                    {client.sCliente}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>

        <div className="added-articles">
          <h3>Artículos Añadidos</h3>
          {addedArticles.length > 0 ? (
            <>
              <ul>
                {addedArticles.map((article, index) => (
                  <li key={index}>
                    {article.sArticuloCorto} - {article.uds} unidades
                    <button
                      type="button"
                      onClick={() => {
                        const newArticles = addedArticles.filter(
                          (_, i) => i !== index
                        );
                        setAddedArticles(newArticles);
                      }}
                      className="remove-button"
                    >
                      <X size={16} />
                    </button>
                  </li>
                ))}
              </ul>

              {selectedClient && (
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("Botón de enviar clickeado");
                    handleSendRequest();
                  }}
                  className="send-button"
                  disabled={loading}
                >
                  <Truck size={24} />
                  {loading
                    ? "Enviando..."
                    : `Enviar Solicitud para ${selectedClient.sCliente}`}
                </button>
              )}
            </>
          ) : (
            <p>No hay artículos añadidos</p>
          )}
        </div>

        {message && (
          <div
            className={`message ${
              message.includes("Error") ? "error" : "success"
            }`}
          >
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

export default MaterialRequest;
