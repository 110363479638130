import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import config from '../config';
import '../styles/modal.css';
import { useAuth } from '../AuthContext';

const ModalPermissions = ({ isOpen, onClose, user }) => {
  const [routes, setRoutes] = useState([]);
  const [error, setError] = useState(null);
  const { logout } = useAuth();

  const fetchRoutes = useCallback(async () => {
    try {
      const environment = process.env.NODE_ENV || 'development';
      const token = localStorage.getItem('authToken');
      const response = await axios.get(`${config[environment].PHP_GET_ROUTES_URL}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setRoutes(response.data);
    } catch (error) {
      console.error('Error fetching routes:', error);
      if (error.response && error.response.status === 401) {
        setError('Error de autenticación. Por favor, inicie sesión de nuevo.');
        logout();
      } else {
        setError('No se pudieron obtener las rutas. Por favor, inténtelo de nuevo más tarde.');
      }
    }
  }, [logout]);

  useEffect(() => {
    if (isOpen && user) {
      fetchRoutes();
    }
  }, [isOpen, user, fetchRoutes]);

  if (!isOpen || !user) return null;
  if (error) return <div>{error}</div>;

  return (
    <div className="modal-overlay-permissions">
      <div className="modal-content-permissions">
        <div className='corner-buttons-permissions'>
          <button className="modal-close-permissions" onClick={onClose}>&times;</button>
        </div>
        <h2>Permisos de {user.sNombre_Completo}</h2>
        <div className='table-permission-staff'>
          <div className='tr-staff-header-permissions'>
            <div className='td-permissions-staff-item-1'>Pantalla</div>
            <div className='td-permissions-staff-item-2'>Permisos</div>
          </div>
          {routes.map((route) => (
            <div key={route.idRuta} className='tr-staff-header-permissions'>
              <div className='td-permissions-staff-item-3'>{route.sRutaNombre}</div>
              <div className='td-permissions-staff-item-4'>
                <div className='td-permissions-staff-item-6'>
                  <input type="checkbox" />
                </div>
                <div className='td-permissions-staff-item-6'>
                  <input type="checkbox" />
                </div>
                <div className='td-permissions-staff-item-6'>
                  <input type="checkbox" />
                </div>
                <div className='td-permissions-staff-item-6'>
                  <input type="checkbox" />
                </div>
                <div className='td-permissions-staff-item-6'>
                  <input type="checkbox" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ModalPermissions;